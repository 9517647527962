/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const PageContent = ({ className, content }) => (
  <div className={className}>
    <div dangerouslySetInnerHTML={{ __html: content }} />
  </div>
)

PageContent.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default styled(PageContent)`
  img {
    width: 100%;
    margin: 5rem auto;
    display: block;
  }

  iframe {
    margin: 5rem auto;
    display: block;
    max-width: 100%;
  }

  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
  }

  p,
  ul,
  ol {
    margin-bottom: 6rem;
  }

  li {
    margin-bottom: 1rem;
    line-height: 1.8;
  }
`
