/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"

import Container from "../components/common/Container"
import Layout from "../components/common/Layout"
import PageContent from "../components/common/PageContent"
import PageHeader from "../components/common/PageHeader"
import SEO from "../components/common/SEO"

import CTAMain from "../components/content/CTAMain"

const DefaultPage = ({ data: { prismicDefaultPage } }) => {
  const { data } = prismicDefaultPage
  return (
    <Layout>
      <SEO
        title={data.title.text}
        description={data.meta_description.text}
        canonicalUrl={data.canonical_url.text}
      />
      <PageHeader
        heading={data.heading.text}
        introduction={data.introduction.html}
        image={data.header_image.url}
        imageAlt={data.header_image.alt}
      />
      <Container>
        <PageContent content={data.content.html} />
      </Container>
      <CTAMain
        heading={data.cta_heading.text}
        text={data.cta_text.text}
        buttonLabel={data.cta_button_label.text}
        subText={data.cta_sub_text.text}
      />
    </Layout>
  )
}

export default DefaultPage

export const pageQuery = graphql`
  query DefaultPageBySlug($uid: String!) {
    prismicDefaultPage(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        meta_description {
          text
        }
        canonical_url {
          text
        }
        heading {
          text
        }
        introduction {
          html
        }
        header_image {
          url
          alt
        }
        content {
          html
        }
        cta_heading {
          text
        }
        cta_text {
          text
        }
        cta_button_label {
          text
        }
        cta_sub_text {
          text
        }
      }
    }
  }
`
